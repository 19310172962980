import React, { Component } from 'react';

import Navbar from './../Navbar/navbar.jsx';
import Navigation from './../Navigation/navigation.jsx';
import './getUsers.css';
import Loader from 'react-loader-spinner'
import actions from './../../store/Actions/index.js';
import { withRouter }  from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { isLogin } from './../../utils/authentication.js';

class GetUsers extends Component{
    state = {
        userList:""
    }
    async componentDidMount() {
        if(!isLogin()){
            this.props.history.push({
                pathname:"/login"
            });
        }
        await this.props.getUsers();
    }

    onClick =async(userId) => {
        await this.props.deleteUser({userId:userId});
        this.props.history.push({
            pathname:"/"
        })
    }
    
    render(){
        const { auth } = this.props;
        return(
            <div>
                <div className="col-md-12 topbar">
                    <Navbar />
                    <Navigation />
                </div>
                <div className="container-fluid">
                    { auth && auth.isLoading ?
                    <div className="text-center">
                        <Loader type="Audio" color="#00BFFF" height={80} width={80} />
                    </div> :
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                  <h3>{auth && auth.getUsers && auth.getUsers.length} Sub Users</h3>
                            </div>
                        </div>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Username</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {auth && auth.getUsers && auth.getUsers.map((list, index) => (
                                <tr key={index}>
                                  <th scope="row">{index+1}</th>
                                  <td>{list.email}</td>
                                  <td><Button varient="primary" type="submit" onClick={this.onClick.bind(this,list._id)}>Delete</Button></td>
                                </tr>
                            ))}
                            { auth && auth.getUsers && auth.getUsers.length == 0 &&
                                <tr>
                                  <td colSpan="3"><b>No Subuser available for this account</b></td>
                                </tr>
                            }
                          </tbody>
                        </table>
                    </div> }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getUsers:(v) => dispatch(actions.getUsers(v)),
    deleteUser:(v) => {dispatch(actions.deleteUser(v))}
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(GetUsers));