import React, { Component } from 'react';
// import { Navbar } from 'react-bootstrap';

import Navbar from './../Navbar/navbar.jsx';
import Navigation from "./../Navigation/navigation.jsx";
import { Form, Button } from 'react-bootstrap';

import actions from './../../store/Actions/index.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';



class CostFormula extends Component{
    state = {
        cost:"",
        companyProfileCost:"",
        executiveSummary:""
    }
    onSubmit = async(evt) => {
        evt.preventDefault();
        await this.props.addFormulaCost({cost:this.state.cost,companyProfileCost:this.state.companyProfileCost,executiveSummary:this.state.executiveSummary});
    }
    
    render(){
        return(
            <div> 
                <div className="col-md-12 topbar">
                    <Navbar />
                    <Navigation />
                </div>
                <div className="col-md-6 offset-md-3 pagebg">
                    <Form onSubmit={this.onSubmit}>
                        <Form.Group>
                            <Form.Label>
                                Cost
                            </Form.Label>
                            <Form.Control type="Number" placeholder="add Cost" required onChange={(evt) => {
                                this.setState({cost:evt.target.value});
                            }}>
                            </Form.Control>    
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Company Profile
                            </Form.Label>
                            <Form.Control type="Number" placeholder="add company profile cost" required onChange={(evt) => {
                                this.setState({companyProfileCost:evt.target.value});
                            }}>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Executive Summary
                            </Form.Label>
                            <Form.Control type="Number" placeholder="add executive summary" required onChange={(evt) => {
                                this.setState({executiveSummary:evt.target.value});
                            }}>

                            </Form.Control>
                        </Form.Group>
                        <div className="text-right">
                            <Button varient="primary" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    cost:state
});

const mapStateToDispatch = dispatch => ({
    addFormulaCost:(v) => dispatch(actions.addFormulaCost(v))
})

export default withRouter(connect(mapStateToProps,mapStateToDispatch)(CostFormula));