import {
    createAction
} from "redux-actions";
import axios from 'axios';

import {
    BACKEND_URL2 as BACKEND_URL
} from './../../config.js';


const GET_EDITOR = createAction("GET_EDITOR");
const GET_EDITOR_LOAD = createAction("GET_EDITOR_LOAD");


export const getEditor  = (page) => dispatch => {
    dispatch(GET_EDITOR_LOAD())
    return axios.get(
        BACKEND_URL + `/v1/explore/getEditor?page=${page}`, {
            headers: {
                token: localStorage.getItem("token")
            }
        }
    ).then((res) => {
        dispatch(GET_EDITOR({data: res.data}))
    }).catch((err) => {
        dispatch(GET_EDITOR({data: null}))
    })
};
