import * as Auth from './AuthActions';
import * as Report from './ReportActions.js';
import * as Editor from './EditorActions.js';



export default {
    ...Auth,
    ...Report,
    ...Editor
};