import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import axios from 'axios';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Navbar from './../Navbar/navbar.jsx';
import Navigation from './../Navigation/navigation.jsx';
import { Button, Card, Form, Table } from 'react-bootstrap';
import Loader from 'react-loader-spinner'
import _ from 'lodash'
import Moment from "moment";
import './usage.css'
import {
    BACKEND_URL
} from './../../config.js';


axios.defaults.headers.common['token'] = localStorage.getItem("token");


class Usage extends React.PureComponent {


  state={
    file:"",
    list: [],
    reportList: [],
    isLoading: true,
    showToken: false
  }

  componentDidMount(){
    this.getuploadedDocs()
    this.getReportList()
  }

  getuploadedDocs = ()=> {
    axios.get(
        BACKEND_URL + "/v1/admin/usage", {
            headers:{
                token:localStorage.getItem("token")
            }
        }
    ).then((res) => {
        console.log(res)
        this.setState({list: res.data, isLoading: false})
    }).catch((err) => {
        this.setState({list: [], isLoading: false})
        console.log(err)
    })
  }

  getReportList = ()=> {
    axios.get(
        BACKEND_URL + "/v1/admin/usage/reports", {
            headers:{
                token:localStorage.getItem("token")
            }
        }
    ).then((res) => {
        console.log(res)
        this.setState({reportList: res.data, isLoading: false})
    }).catch((err) => {
        this.setState({reportList: [], isLoading: false})
        console.log(err)
    })
  }

  handleShow = ()=> {
    this.setState({showToken: !this.state.showToken})
  }


  render() {
    const { list, reportList, isLoading } = this.state;
    return (
      <div>
        <div className="col-md-12 topbar">
            <Navbar />
        </div>

        <main role="main" className="p-5">
          <div className="my-3 p-3 bg-white rounded shadow-sm">
            <h6 className="border-bottom pb-2 mb-2">Token: <b>{this.state.showToken ? reportList && reportList.api && reportList.api.token : 'xxxxxxx******xxxxxxx'}</b> <a style={{color: 'blue', cursor: 'pointer'}} onClick={this.handleShow}>{this.state.showToken ? 'Hide' : 'Show'}</a></h6>
            <div className="card mb-4">
              <div className="card-body">
                <SwaggerUI defaultModelsExpandDepth={-1} url="./swagger.json" />
              </div>
            </div>
            <h6 className="border-bottom border-gray pb-2 mb-0">API USAGE</h6>
            <div className="card">
              <div className="card-body">
              <Table striped bordered hover size="sm">
                <tbody>
                  { !isLoading && reportList.usage && reportList.usage.length == 0 &&
                    <tr>
                      <td colSpan="3">No Report Usage Available</td>
                    </tr>
                  }
                </tbody>
              </Table>
              {
              !isLoading && 
                  <Table striped bordered hover size="sm" className="mt-3">
                    <thead>
                      <tr>
                        <th>Report Name</th>
                        <th>Section Name</th>
                        <th style={{width: 350}}>View/Download Count</th>
                      </tr>
                    </thead>
                    <tbody>
                    { reportList && reportList.usage && reportList.usage.map((value, key) => 
                        <tr key={key}>
                          <td>{value.reportName}</td>
                          <td>{value.sectionName}</td>
                          <td>{value.total}</td>
                        </tr>
                        )
                    }
                    </tbody>
                  </Table>
              }

              <Table striped bordered hover size="sm">
                <tbody>
                  { !isLoading && list.length == 0 &&
                    <tr>
                      <td colSpan="3">No Usage Available</td>
                    </tr>
                  }
                </tbody>
              </Table>
              { !isLoading &&
              <Table striped bordered hover size="sm" className="mt-3">
                <thead>
                  <tr>
                    <th>Endpoint</th>
                    <th style={{width: 350}}>Usage Count</th>
                  </tr>
                </thead>
                <tbody>
                    { list.map((value, key) => 
                        <tr key={key} >
                          <td>{value._id}</td>
                          <td>{value.total}</td>
                        </tr>
                    )}
                    { list && list.length == 0 &&
                        <tr>
                          <td colSpan="2">No Usage Available</td>
                        </tr>
                    }
                </tbody>
              </Table> }
              
              { isLoading &&
                <div style={{textAlign: 'center'}}>
                  <Loader
                   type="Oval"
                   color="#00BFFF"
                   height={100}
                   width={100}
                  /> 
                </div>
              }

              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state){
    return { }
}

export default withRouter(connect(
  mapStateToProps
)(Usage))

