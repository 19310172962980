import { handleActions } from "redux-actions";


const INITIAL_STATE = {
    editorList: { fields: [] },
    isLoadingEditor: false
};

const EditorReducer = handleActions({
    GET_EDITOR:(state, action) => {
        return {...state, editorList: action.payload.data, isLoadingEditor: false }
    },
    GET_EDITOR_LOAD:(state, action) => {
        return {...state, isLoadingEditor: true }
    }
},INITIAL_STATE);

export default EditorReducer;