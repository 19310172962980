import React , { Component } from 'react';
import { Form,Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import actions from './../../store/Actions/index.js';

class UpdateReportName extends Component{
    state={
        newReportName:""
    }
    onSubmit=async(evt) => {
        evt.preventDefault();
        await this.props.onReportNameChange({reportId:this.props.reportId,newReportName:this.state.newReportName});
        window.location.reload(true);
    }
    
    render(){
        return(
            <div>
                <Form onSubmit={this.onSubmit}>
                    <Form.Group>
                        <Form.Label>
                            New Name
                        </Form.Label>
                        <Form.Control type="text" placeholder="Update Report Name" required onChange={(evt) => {
                                this.setState({newReportName:evt.target.value});
                            }}>
                        </Form.Control>
                    </Form.Group>
                    <Button varient="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    state:state
});

const mapDispatchToProps = dispatch => ({
    onReportNameChange:(v) => dispatch(actions.onReportNameChange(v))
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UpdateReportName));