import React, { Component } from 'react';

import './navigation.css';

import { Nav } from 'react-bootstrap';

import { connect } from "react-redux";
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import AddNewReportForm from "./../Forms/AddNewReportForm/addNewReportForm.jsx";
import AddCustom from "./../Forms/AddCustom/addCustom.jsx";
import AddNewUser from './../Forms/AddNewUser/addNewUser.jsx';


class Navigation extends Component {
    constructor() {
        super();
        this.state = {
            Menu1: 'Home',
            Menu2: 'Add Report', 
            show:false,
            showCustom: false,
            isUserCreate:false,
            showNewUser:false,
            showGetLink:false,
            showClient:false,
        };
    }

    onClick = () => {
        this.props.history.push({
            pathname:"/"
        })
    };

    onCreateNew = () => {
        this.setState({show:true});   
    };
    
    showCustom = () => {
        this.setState({showCustom:true});   
    };

    handleClose =  () => {
        this.setState({show:false, showCustom: false});
    }

    componentDidMount(){
        if(localStorage.getItem("userType") === "superAdmin"){
            this.setState({isUserCreate:true});
        }
    }
    onUserCreate = () => {
        this.setState({showNewUser:true});
    }

    onGetLink = () => {
        this.props.history.push({
            pathname:"/getusers"
        });
    }
    handleCreateUser = () => {
        this.setState({showNewUser:false});
    }

    handleReportClose = () => {
        this.setState({show:false, showCustom: false});
        window.location.reload(true);
    }

    onCostFormula = () => {
        this.props.history.push({
            pathname:"/costformula"
        });
    }
    

    
    render() {
        console.log(this.props.auth)
        return (
            <div className="">
                <nav className="nav nav-underline" style={{background: '#f0f0f0'}}>
                    <Nav.Link style={{borderRight: '1px solid #cfcfcf'}} onClick={this.onClick}>{this.state.Menu1}</Nav.Link>
                    { this.state.isUserCreate && <Nav.Link style={{borderRight: '1px solid #cfcfcf'}} onClick={this.onCreateNew}>{this.state.Menu2}</Nav.Link> }
                    { this.state.isUserCreate && <Nav.Link style={{borderRight: '1px solid #cfcfcf'}} onClick={this.showCustom}>Add Custom Report</Nav.Link> }
                    { this.state.isUserCreate && <Nav.Link style={{borderRight: '1px solid #cfcfcf'}} onClick={this.onUserCreate}>Add Sub User</Nav.Link> }
                    { this.state.isUserCreate && <Nav.Link style={{borderRight: '1px solid #cfcfcf'}} onClick={this.onGetLink}>Sub Users</Nav.Link> }
                    {/* { this.state.isUserCreate && <Nav.Link style={{borderRight: '1px solid #cfcfcf'}} onClick={this.onCostFormula}>Cost Formula</Nav.Link>} */}
                </nav>
                <Modal size="lg" show={this.state.show} onHide={this.handleClose} className="modal-size">
                    <Modal.Header closeButton>
                        <div className="row col-md-12">
                            <Modal.Title className="col-md-6 heading-details">Add new report</Modal.Title>
                        </div>   
                    </Modal.Header>
                    <Modal.Body className="modal-body" style={{"overflowY":"auto"}}>
                        <div>
                            <AddNewReportForm handleClose={this.handleClose} onSave={this.handleReportClose}/>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={this.state.showCustom} onHide={this.handleClose} className="modal-size">
                    <Modal.Header closeButton>
                        <div className="row col-md-12">
                            <Modal.Title className="col-md-6 heading-details">Add Custom Report</Modal.Title>
                        </div>   
                    </Modal.Header>
                    <Modal.Body className="modal-body" style={{"overflowY":"auto"}}>
                        <div>
                            <AddCustom handleClose={this.handleClose} onSave={this.handleReportClose}/>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showNewUser} onHide={this.handleCreateUser} className="modal-size">
                    <Modal.Header closeButton>
                        <div className="row col-md-12">
                            <Modal.Title className="col-md-6 heading-details">Add new user</Modal.Title>
                        </div>   
                    </Modal.Header>
                    <Modal.Body className="modal-body" style={{"overflowY":'auto'}}>
                        <div>
                            <AddNewUser onSave={this.handleCreateUser} />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth:state.auth
});

export default withRouter(connect(mapStateToProps)(Navigation))