import React, { Component } from 'react';

import _ from 'lodash';

import "./section.css";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Button } from 'react-bootstrap';
import actions from './../../store/Actions/index.js';
import * as FileSaver from 'file-saver'
import { Modal } from 'react-bootstrap';

class Section extends Component{
    state={
        sections:[],
        isAdminUser:false,
        addCost:false,
        sectionId:"",
        showChangeName:false
    };
    
    componentDidMount = () => {
        let sections = _.filter(this.props.detail.sections,{chapterId:this.props.chapterId});
        sections = _.sortBy(sections,['sequence']);
        this.setState({sections:sections});
        if(localStorage.getItem("userType") === "superAdmin"){
            this.setState({isAdminUser:true});
        }
    }

    onClick = async(sectionId) => {
        this.props.history.push({
            pathname:"/viewsection"
        },{
            state:{
                sectionId:sectionId,
                reportId:this.props.reportId,
                chapterId:this.props.chapterId,
                chapterName:this.props.chapterName,
                sectionName:_.find(this.props.detail.sections,{_id:sectionId}).sectionName,
                reportName:this.props.reportName
            }
        });
    }


    render(){
        return(
            <div className="col-md-12 sectionname">
                {this.state && this.state.sections && this.state.sections.map((section, index) => (
                        <div key={section._id}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Nav.Link>
                                        <span>
                                            {this.props.chapterNumber}
                                        </span>
                                        <span>
                                            .{index+1}-
                                        </span>    
                                        <span onClick={this.onClick.bind(this,section._id,section.sectionName)}>
                                            {section.sectionName && <span>
                                                {section.sectionName}
                                            </span>}
                                            {!section.sectionName && <span>
                                                description-dummy heading
                                            </span>}
                                        </span>
                                    </Nav.Link>
                                </div>                                
                            </div>
                        </div>
                    ))}
            </div>

        )
    }
}

const mapStateToProps = state => ({
    section:state
});

const mapDispatchToProps = dispatch => ({
    XMLDownload:(v) => dispatch(actions.XMLDownload(v)),
    onSectionDelete:(v) => dispatch(actions.onSectionDelete(v))
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Section));