import React, { Component } from 'react';

import { Form, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from './../../store/Actions/index.js';
import _ from 'lodash';

import AddText from './../AddText/addText.jsx';
import AddImage from './../AddImage/addImage.jsx';
import AddGraph from './../AddGraph/addGraph.jsx';
import AddTable from './../AddTable/addTable.jsx';
// import { MDBSpinner } from 'mdbreact';
import Loader from 'react-loader-spinner'

class AddData extends Component{
    state={
       dataTypes:[], 
       dataType:"",
       chapters:[],
       chapterName:"",
       sections:[],
       sectionName:"",
       showSelect:true,
       shown:true,
       showData:false,
       type:"",
       heading:"",
       description:"",
       file:"" ,
       sectionId:"",
       chapterId:"",
       PDF:"",
       sectionWisePDF:"",
       apiWait:false
    };
    async componentDidMount(){
        await this.props.dataExist({reportId:this.props.reportId});
        console.log("this props",this.props.state.list.data);
        this.setState({isDataExist : this.props.state.list.data.isDataPresent});
        console.log("this props",this.state.isDataExist);
    }


    onSubmit = async(evt) => {
        evt.preventDefault();
        this.setState({apiWait:true});
        await this.props.addPDF({PDF:this.state.PDF,reportId:this.props.reportId});
        await this.props.addPDFSectionWise({sectionWisePDF:this.state.sectionWisePDF,reportId:this.props.reportId});
        

        this.setState({apiWait:false});
        this.props.onSave();
    }

    render(){
        return(
            <div>
                {this.state.shown && 
                    <div>
                        <Form onSubmit={this.onSubmit}>
                            
                            <div className="col-md-12">
                                <Form.Group>
                                    <Form.Label>
                                        PDF 
                                    </Form.Label>    
                                    <Form.Control required type="file" accept=".pdf" onChange={(evt) => {
                                        this.setState({PDF:evt.target.files[0]});
                                    }}>
                                    </Form.Control>
                                </Form.Group>
                            </div>

                            
                            <div className="col-md-12">
                                <Form.Group>
                                    <Form.Label>
                                        Section Wise PDF
                                    </Form.Label>
                                    <Form.Control required type="file" accept=".pdf" onChange={(evt) => {
                                        this.setState({sectionWisePDF: evt.target.files[0]})
                                    }}>
                                    </Form.Control>
                                </Form.Group>
                            </div>



                            {/* <div className="col-md-12">
                                <p id="emailerror" className="text-danger"></p>
                            </div> */}
                            {this.state.isDataExist && <div>
                                <h2>
                                   Data is already submit, if you will upload new file it will update the data
                                </h2>
                            </div>}
                            {!this.state.apiWait && <div className="col-md-12 text-right">
                                <Button varient="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                            }
                            {this.state.apiWait && <div className="col-md-12 text-right">
                            <Loader
                               type="Oval"
                               color="#00BFFF"
                               height={100}
                               width={100}
                            //    timeout={3000} //3 secs
                            />
                            </div>  
                            }
                        </Form>
                    </div>
                }
                {/* {this.state.showData &&
                    <div>
                        { 
                            this.state.dataType === "text" ? <AddText reportId={this.props.reportId} sectionId={this.state.sectionId} chapterId={this.state.chapterId} onSave={this.props.onSave} /> : 
                            this.state.dataType === "image" ? <AddImage reportId={this.props.reportId} sectionId={this.state.sectionId} chapterId={this.state.chapterId} onSave={this.props.onSave} /> : 
                            this.state.dataType === "graph" ? <AddGraph reportId={this.props.reportId} sectionId={this.state.sectionId} chapterId={this.state.chapterId} onSave={this.props.onSave} /> : 
                            this.state.dataType === "table" ? <AddTable reportId={this.props.reportId} sectionId={this.state.sectionId} chapterId={this.state.chapterId} onSave={this.props.onSave} /> : "check"
                        }
                    </div>
                }     */}
            </div>    
        )
    }
}

const mapStateToProps = state => ({
    // dataTypes:state.list.dataTypes
    state:state,
});

const mapDispatchToProps = dispatch => ({
    // getDataType:(v) => dispatch(actions.getDataType(v)),
    // addData:(v) => dispatch(actions.addData(v))
    addPDF:(v) => dispatch(actions.addPDF(v)),
    // addPDF:(v) => dispatch(addPDF(v))
    addPDFSectionWise:(v) => dispatch(actions.addPDFSectionWise(v)),
    dataExist:(v) => dispatch(actions.dataExist(v)),
    addOtherDocs:(v) => dispatch(actions.addOtherDocs(v))
});

export default withRouter (connect(mapStateToProps,mapDispatchToProps)(AddData));