import React, { Component } from 'react';

import actions from './../../store/Actions/index.js';
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import Navbar from './../Navbar/navbar.jsx';
import './home.css';
import Navigation from './../Navigation/navigation.jsx';

import { isLogin } from './../../utils/authentication.js';
import Loader from 'react-loader-spinner'
import { Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import UpdateReportName from './../UpdateReportName/updateReportName.jsx';
import AddCost from './../AddCost/addCost.jsx';

class Home extends Component{
    state = {
        list:null,
        show:false,
        reportId:"",
        isSuperAdmin:false,
        currentCost: null
    }
    async componentDidMount(){
        if(!isLogin()){
            this.props.history.push({
                pathname:"/login"
            });
        }
        this.props.getCustomReportList();
        this.props.getList();
    }

    onSubmit=(id) => {
        this.props.history.push({
            pathname:"/detail",
            state:{
                _id:id            
            }
        })
    };

    onUpdateClick = (reportId) => {
        this.setState({show:true}); 
        this.setState({reportId:reportId});
    }

    onDeleteClick = (reportId) => {
        if(window.confirm('Delete the item?')){
           this.props.onDeleteClick({reportId:reportId});
           // window.location.reload(true);
        }
    }

    onUpdateClose = () => {
        this.setState({show:false});
    }

    componentWillMount = () => {
        if(localStorage.getItem("userType") === "superAdmin"){
            this.setState({isSuperAdmin:true});
        }
    }

    onHide = async() => {
        this.setState({addCost:false});
        this.componentDidMount()
    }
    

    render(){
        const { list, customList } = this.props;
        console.log(customList)
        return(
            <div> 
                <div className="col-md-12 topbar">
                    <Navbar />
                    <Navigation />
                </div>
                <div className="container-fluid">
                    { list.isLoading && list.isCustomLoading ?
                    <div className="text-center">
                        <Loader type="Audio" color="#00BFFF" height={80} width={80} />
                    </div> :
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                  <h3>{list && list.list && list.list.length} Reports From BIS</h3>
                            </div>
                        </div>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Report Name</th>
                              { /* <th scope="col">Action</th> */ }
                            </tr>
                          </thead>
                          <tbody>
                            {list && list.list && list.list.map((listObj, index) => (
                                <tr key={index}>
                                  <th scope="row">{index+1}</th>
                                  <td>{listObj.reportName}</td>
                                  
                                      <td>
                                        <DropdownButton id="dropdown-basic-button" title="Action" size="sm" variant="secondary">
                                          <Dropdown.Item onClick={this.onSubmit.bind(this,listObj._id)}>View</Dropdown.Item>
                                          <Dropdown.Divider />
                                          {
                                              /*
                                              <Dropdown.Item onClick={this.onUpdateClick.bind(this,listObj._id)}>Rename</Dropdown.Item>
                                              <Dropdown.Divider />
                                              <Dropdown.Item onClick={this.onDeleteClick.bind(this,listObj._id)}>Delete</Dropdown.Item>

                                              */
                                          }
                                          </DropdownButton>

                                      </td> 
                                  
                                </tr>
                             ))}
                            { list && list.list && list.list.length == 0 &&
                                <tr>
                                  <td colSpan="3"><b>No BIS report available for your account!</b></td>
                                </tr>
                            }
                          </tbody>
                        </table>

                        { customList &&
                        <div className="row">
                            <div className="col-md-12">
                                  <h3>{customList && customList.length} Uploaded Reports</h3>
                            </div>
                        </div> }
                        { customList &&
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Report Name</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customList && customList.map((listObj, index) => (
                                <tr key={index}>
                                  <th scope="row">{index+1}</th>
                                  <td>{listObj._source.reportName}</td>
                                  <td>
                                    <DropdownButton id="dropdown-basic-button" title="Action" size="sm" variant="secondary">
                                      <Dropdown.Item onClick={this.onDeleteClick.bind(this,listObj._source.reportId)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                  </td>
                                </tr>
                             ))}
                            { customList && customList.length == 0 &&
                                <tr>
                                  <td colSpan="3"><b>No report available for your account!</b></td>
                                </tr>
                            }
                          </tbody>
                        </table> }
                    </div> }
                </div>
                
                <Modal size="lg" show={this.state.show} onHide={this.onUpdateClose} className="modal-size">
                    <Modal.Header closeButton>
                        <div className="row col-md-12">
                            <Modal.Title className="col-md-6 heading-details">Update report Name</Modal.Title>
                        </div>   
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <UpdateReportName reportId={this.state.reportId} />
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={this.state.addCost} onHide={this.onHide} className="modal-size">
                    <Modal.Header closeButton>
                        <div className="row col-md-12">
                            <Modal.Title className="col-md-6 heading-details">Add Cost</Modal.Title>
                        </div>   
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <AddCost reportId={this.state.reportId} cost1={this.state.currentCost} onSave={this.onHide} />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
} 

const mapStateToProps = state => ({
    list: state.list,
    customList: state.list.customList
});

const mapDispatchToProps = dispatch => ({
    getList:(v) => dispatch(actions.getList(v)),
    getCustomReportList:(v) => dispatch(actions.getCustomReportList(v)),
    onDeleteClick:(v) => dispatch(actions.onDeleteClick(v))
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Home));