import React, { Component } from 'react';

import "./sideBanner.css";
import { Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class SideBanner extends Component{
    constructor(){
        super();
        this.state = {
            title:"Admin panel",
            subheading:"easy way to upload data"
        }
    }
    onForgetPassword=async() => {
        this.props.history.push({
            pathname:"/forgetpassword"
        });
    }

    render(){
        return(
            <div className="sidebanner">
                <div className="col-md-6 left">
                    <h1><span>{this.state.title}</span>{this.state.subheading}</h1>
                </div>
                <div className="col-md-6 left">
                    <Button variant="light" type="submit" onClick={this.onForgetPassword}>
                          Forget Password
                    </Button>
                </div>
            </div>    
        )
    }
}

const mapStateToProps = (state) => ({
    auth:state.auth
});

export default withRouter(connect(mapStateToProps)(SideBanner));