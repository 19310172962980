import React, { Component } from 'react';

import { Line, Bar, Pie, Radar, Scatter,Bubble,HorizontalBar,Doughnut } from 'react-chartjs-2';

import _ from 'lodash';
import { JsonToTable } from 'react-json-to-table';

class ShowGraph extends Component{
    state = {
        data:null,
        dataValue:[],
        options:{},
        fill:{},
        data1:null
    }
    componentDidMount(){
        let colour = ["#95D668","#ECE78A","#C64E33","#3D63C8"]
        if(this.props.graphType !== "scatter" && this.props.graphType !== "bubble"){
            let dataObj = [];
            const objectLength = Object.keys(this.props.data[0]).length;
            for(let i=0;i<objectLength;i++){
            dataObj.push([]);
            }
            this.props.data.map((data) => {
                const keys = Object.keys(data);
                let i = 0;
                keys.forEach((key) => {
                    dataObj[i].push(data[key]);
                    i++;
                });
            });
            let graphData = [];
            let i = 1;
            dataObj.slice(1).forEach((dataObj) => {
                let obj = {}
                obj["label"] = 'graph' + i;
                obj["data"] = dataObj;
                obj["backgroundColor"] = colour;
                if(this.props.graphType === "line"){
                    obj["fill"] = false;
                }
                if(this.props.graphType === "mixed"){
                    if(i > (objectLength - 1)/2){
                        obj["type"] = "line";
                        obj["fill"] = false
                    }
                }
                graphData.push(obj);
                i++;
            });
            let dataObj1;
            let dataObj2;    
            if(this.props.graphType === "graphPie"){
                dataObj1 = [graphData[0]];
                dataObj2 = [graphData[2]];
            }
            if(this.props.graphType === "barPie"){
                dataObj1 = graphData.slice(0,graphData.length/2);
                dataObj2 = graphData.slice(graphData.length / 2)
            }
            if(this.props.graphType === "twoHorizontalBar" || this.props.graphType === "twoBar"){
                dataObj1 = [graphData[0]];
                dataObj2 = [graphData[2]]
            }
            if(this.props.graphType === "graphPie" || 
               this.props.graphType === "barPie" || 
               this.props.graphType === "twoHorizontalBar" ||
               this.props.graphType === "twoBar" 
            ){    
                this.setState({data:{
                    labels:dataObj[0],
                    datasets:dataObj1
                }});
                this.setState({data1:{
                    labels:(this.props.graphType === "twoBar" || this.props.graphType === "twoHorizontalBar") ? dataObj[2] : dataObj[0],
                    datasets:dataObj2
                }});
            }else{
                this.setState({data:{
                    labels:dataObj[0],
                    datasets:graphData
                }})
            }
        }else{
            this.setState({
                data:{
                    labels:"graph1",
                    datasets:[{
                        label: 'Scatter Dataset',
                        data:this.props.data,
                        backgroundColor:colour
                    }],
                }
            })
        }  
        this.setState({options:{
            scales: {
              xAxes: [{ stacked: true }],
              yAxes: [{ stacked: true }]
            }
        }})
    }
    render(){
        return(
            <div>
                {this.props.graphType === "bar" && <Bar ref="chart" data={this.state.data} />}
                {this.props.graphType === "line" && <Line ref="chart" data={this.state.data} options={this.state.fill} />}
                {this.props.graphType === "pie" && <Pie ref="chart" data={this.state.data} />}
                {this.props.graphType === "scatter" && <Scatter ref="chart" data={this.state.data} />}
                {this.props.graphType === "mixed" && <Bar ref="chart" data={this.state.data} />}
                {this.props.graphType === "bubble" && <Bubble ref="chart" data={this.state.data} />}
                {this.props.graphType === "horizontalBar" && <HorizontalBar ref="chart" data={this.state.data} />}
                {this.props.graphType === "stackedBar" && <Bar ref="chart" data={this.state.data} options={this.state.options} />}
                {this.props.graphType === "stackedHorizontalBar" && <HorizontalBar ref="chart" data={this.state.data} options={this.state.options} />}
                {this.props.graphType === "radar" && <Radar ref="chart" data={this.state.data} /> }
                {this.props.graphType === "doughnut" && <Doughnut ref="chart" data={this.state.data} />}
                {this.props.graphType === "graphPie" && <div className="row">
                    <div className="col-md-6">
                        <Bar ref="chart" data={this.state.data} />
                    </div>
                    <div className="col-md-6"> 
                        <Pie ref="chart" data={this.state.data1} />    
                    </div>
                </div>}
                {this.props.graphType === "barPie" && <div className="row">
                    <div className="col-md-6">
                        <Bar ref="chart" data={this.state.data}></Bar>
                    </div>
                    <div className="col-md-6">
                        <Pie ref="chart" data={this.state.data1}></Pie>
                    </div>
                </div>}
                {this.props.graphType === "graphTable" && <div className="row">
                    <div className="col-md-6">
                        <Bar ref="chart" data={this.state.data} />
                    </div>
                    <div className="col-md-6">
                        <JsonToTable json={this.props.data.slice(0)} />
                    </div>
                </div>}
                {this.props.graphType === "twoHorizontalBar" && <div className="row">
                    <div className="col-md-6">
                        <HorizontalBar ref="chart" data={this.state.data} />
                    </div>
                    <div className="col-md-6">
                        <HorizontalBar ref="chart" data={this.state.data1} />
                    </div>
                </div>}
                {this.props.graphType === "twoBar" && <div className="row">
                    <div className="col-md-6">
                        <Bar ref="chart" data={this.state.data} />
                    </div>
                    <div className="col-md-6">
                        <Bar ref="chart" data={this.state.data1} />
                    </div>
                </div>}    
            </div>
        )
    }
}

export default ShowGraph;