import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import axios from 'axios';
import Navbar from './../Navbar/navbar.jsx';
import Navigation from './../Navigation/navigation.jsx';
import Pusher from 'pusher-js';
import { Button, Spinner, ProgressBar, Accordion, Card, useAccordionToggle } from 'react-bootstrap';

import {
    BACKEND_URL2 as BACKEND_URL
} from './../../config.js';


// Pusher.logToConsole = true;

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <button
      type="button"
      className="btn btn-primary"
      style={{ cursor: 'pointer', float: 'right' }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

class Scrappers extends React.PureComponent {

  state = {
    liveStatus: null,
    process: 0,
    smartResponse: null,
    url: 'https://docs.google.com/spreadsheets/d/1pUcgQgqi-DibfXd0VVqE_ImxaluFkX8UylY12Mz42ps/edit#gid=0',
    failed: false,
    isLoading: false,
    errorId: null
  }

  componentDidMount(){


    var pusher = new Pusher('143db21116755d77f805', {
      cluster: 'ap2',
      forceTLS: true
    });

    var channel = pusher.subscribe('scrapper');
    channel.bind('key-intel', (data)=> {
      this.setState({ liveStatus: data.message ? data.message : this.state.liveStatus, process: data.process ? data.process : this.state.process, errorId: data.errorId, isLoading: false })
      console.log(data);
    });


  }

  handleChange = (e)=> {
    this.setState({ url: e.target.value })
  }

  onTypeChanged = (e)=> {
    this.setState({
      type: e.currentTarget.value
    });
  }

  handleSave = ()=> {
    let url = this.state.url
    let conf = window.confirm("Are you sure ?")
    if(conf) {
      if(url.length > 38){
        url = url.split('/')[5]
        this.setState({isLoading: true})
        console.log(url)
        axios.post(
          BACKEND_URL + "/v1/scrappers/intelME", {
              url: url
          }, {
              headers: {
                  token: localStorage.getItem("token")
              }
          }
        ).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
      } else {
        alert('Invalid URL')
      }
    }
  }


  render() {
    const { smartResponse } = this.state;
    console.log(this.state.url)
    return (
      <div>
        <div className="col-md-12 topbar">
            <Navbar />
        </div>


        <main role="main" className="container">
          <div className="my-3 p-3 bg-white rounded shadow-sm">
            <h6 className="border-bottom border-gray pb-2 mb-0">BIS Workspace</h6>
            <div className="card">
              <div className="card-body">
              <h5 className="card-title">Market Engineering Scrapper</h5>
                <div className="form-group">
                  <label style={{color: '#0007d4'}}>Google Spreadsheets URL</label>
                  <input onChange={this.handleChange} defaultValue={this.state.url} className="form-control" placeholder="https://docs.google.com/spreadsheets/d/17zsBYpZ4Jmi0yIDfmnHm4q7HdOMIx8kkLGkUwF6rIqI/edit#gid=102110434" />
                  
                </div>
                <Spinner animation="grow" />
                { this.state.process !== 0  &&  <ProgressBar animated now={this.state.process} /> }
                { this.state.process !== 0 && <small style={{color: '#037cff', fontSize: 15 }} className="form-text text-center">{this.state.liveStatus}</small> }
                { this.state.process === 100 || this.state.process === 0  ? <Button disabled={this.state.isLoading} variant="primary" onClick={this.handleSave} >{this.state.process == 100 ? 'Submit Again' : (this.state.isLoading ? 'Please wait....' : 'Submit')}</Button> :  null }
                { this.state.errorId && <small style={{color: 'red', fontSize: 15 }} className="form-text text-center">Error Id: {this.state.errorId}</small> }
              </div>
              { this.state.smartResponse &&
                <Accordion style={{padding: 10}}>
                  <b style={{color: 'red'}}>SMART RESPONSE</b>
                  <Card style={{marginBottom: 5}}>
                    <Card.Header style={{padding: 5}}>
                      <b>Saved Records</b>:  {smartResponse.savedRecords } out of { smartResponse.totalRecords }
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>Hello! I'm the body</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card style={{marginBottom: 5}}>
                    <Card.Header style={{padding: 5}}>
                      <b>Company Name Not Match</b>:  {smartResponse.idsNotAvailable.length} out of { smartResponse.companiesFound }
                      <CustomToggle eventKey="1">Show Details</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <ul className="list-group">
                          { smartResponse.idsNotAvailable && smartResponse.idsNotAvailable.map((data, index) =>
                            <li key={index} className="list-group-item"><b>{index+1}</b>: {data}</li>
                          )}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card style={{marginBottom: 5}}>
                    <Card.Header style={{padding: 5}}>
                      <b>Wrong Topics</b>:  {smartResponse.topics.wrong} out of {smartResponse.savableRecors}
                      <CustomToggle eventKey="2">Show Details</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <ul className="list-group">
                          { smartResponse.topics.data && smartResponse.topics.data.map((data, index) =>
                            <li key={index} className="list-group-item"><b>{index}</b>: {data}</li>
                          )}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              }
            </div>
              <small>1. Must be public google sheet:  <b>Share -> Advanced -> Who has access ( Public on the web - Anyone on the Internet can find and view  ) </b></small>
              <br />
              <small>2. Sheet Format:  <b>https://docs.google.com/spreadsheets/d/17zsBYpZ4Jmi0yIDfmnHm4q7HdOMIx8kkLGkUwF6rIqI/edit#gid=102110434</b></small>
              
          </div>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state){
    return { }
}

export default withRouter(connect(
  mapStateToProps
)(Scrappers))

