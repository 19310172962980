import React, { Component } from 'react';

import actions from './../../store/Actions/index.js';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Loader from 'react-loader-spinner'

import Navbar from './../Navbar/navbar.jsx';
import Navigation from './../Navigation/navigation.jsx';
import Section from './../Section/section.jsx';
import { Button } from 'react-bootstrap'; 

import { Modal } from 'react-bootstrap';

import AddData from './../AddData/addData.jsx';

import "./fileDetail.css";

import { isLogin } from './../../utils/authentication.js';

class FileDetail extends Component{
    state={
        detail:"",
        chapters:[],
        chapterName:"",
        show:false,
        showSection:false,
        sections:[],
        isSuperAdmin:false,
        updateChapterShow:false,
        _id:"",
        idNumber:null
    }
    async componentDidMount(){
        if(!isLogin()){
            this.props.history.push({
                pathname:"/login"
            });
        }
        await this.props.fileDetail({_id:this.props.location.state._id ? this.props.location.state._id : this.props.location.state.state._id});
        this.setState({detail:this.props.detail,chapters:this.props.detail.chapters,sections:this.props.detail.sections, otherDocList: this.props.detail.otherFiles});
        if(localStorage.getItem("userType") === "superAdmin"){
            this.setState({isSuperAdmin:true});
        }
    }

    onClick=(evt) => {
        evt.preventDefault();
        this.setState({show:true});
    }
    handleClose=() => {
        this.setState({show:false});
    }
    onSection=(evt) => {
        evt.preventDefault();
        this.setState({showSection:true});
    }
    onSectionClose=(evt) => {
        this.setState({showSection:false});
    }
    onData=(evt) => {
        this.setState({showData:true});
    }
    onDataClose=(evt) => {
        this.setState({showData:false});
    }
    onSubmit=async() => {
        await this.props.fileDetail({_id:this.props.location.state._id});
        this.setState({show:false});
        this.setState({detail:this.props.detail,chapters:this.props.detail.chapters,sections:this.props.detail.sections});
    }
    onSectionSubmit=async() => {
        await this.props.fileDetail({_id:this.props.location.state._id});
        this.setState({showSection:false});
        this.setState({detail:this.props.detail,chapters:this.props.detail.chapters,sections:this.props.detail.sections});
    }
    onDataSubmit=async() => {
        this.setState({showData:false});
    }

    onChapterUpdate = (chapterId) => {
        this.setState({updateChapterShow:true});
        this.setState({chapterId:chapterId});
    }

    onChapterUpdateClose = () => {
        this.setState({updateChapterShow:false});
    }

    onChapterDelete = (chapterId) => {
        if(window.confirm('Delete the item?')){
            this.props.onChapterDelete({chapterId:chapterId});
            window.location.reload(true);
        }
    }


    render(){
        const { list } = this.props;
        console.log(this.props.list.isLoadingDetails, list, "21")
        return(
            <div>
                <div className="col-md-12 topbar">
                    <Navbar />
                    <Navigation />
                </div> 

                <div className="container-fluid">
                   { list && list.isLoadingDetails ?
                    <div className="text-center">
                        <Loader type="Audio" color="#00BFFF" height={80} width={80} />
                    </div> :
                    <div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                  <h3 className="float-left">*{list && list.detail &&  list.detail.reportName}</h3>
                                  { this.state.isSuperAdmin &&
                                      <Button className="float-right" varient="primary" type="submit" onClick={this.onData}>
                                        Add Data
                                      </Button>
                                   }
                            </div>
                        </div>
                        <table className="table table-bordered">
                          <tbody>
                            {list && list.detail &&  list.detail.chapters && list.detail.chapters.map((chapter, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                      <td>{index+1}-{chapter.chapterName}</td>
                                    </tr>
                                    <tr>
                                        <Section chapterId={chapter._id} detail={list.detail} chapterNumber={index+1} reportId={this.props.location.state._id} chapterName={chapter.chapterName} reportName={list.detail.reportName} />
                                    </tr>
                                </React.Fragment>
                             ))}
                          </tbody>
                        </table>

                    </div> }
                   
                    
                    <Modal show={this.state.showData} onHide={this.onDataClose} className="modal-size" size="lg" style={{"height":"600px"}}>
                        <Modal.Header closeButton>
                            <div className="row col-md-12">
                                <Modal.Title className="col-md-6 heading-details">Add data</Modal.Title>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{"overflowY":"auto","overflowX":"auto"}}>
                            <AddData chapters={this.state.chapters} reportId={this.props.detail._id} sections={this.props.detail.sections} onSave={this.onDataSubmit}/> 
                        </Modal.Body>
                    </Modal>

                   

                </div>
                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    detail: state.list.detail,
    list: state.list
});


const mapDispatchToProps = dispatch => ({
    fileDetail:(v) => dispatch(actions.fileDetail(v)),
    onChapterDelete:(v) => dispatch(actions.onChapterDelete(v))
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(FileDetail));