import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import axios from 'axios';
import Navbar from './../Navbar/navbar.jsx';
import Navigation from './../Navigation/navigation.jsx';
import { Button, Card, Form, Table } from 'react-bootstrap';
import Loader from 'react-loader-spinner'
import _ from 'lodash'
import Moment from "moment";
import {
    BACKEND_URL
} from './../../config.js';



axios.defaults.headers.common['token'] = localStorage.getItem("token");


class Documents extends React.PureComponent {


  state={
    file:"",
    list: [],
    isLoading: true
  }

  componentDidMount(){
    this.getuploadedDocs()
  }

  handleDelete = (id)=> {
    
  } 

 
  uploadDocs = (evt)=> {
    evt.preventDefault(); 

    this.setState({isLoading: true})

    const formData = new FormData();
    formData.append('file', this.state.file);

    axios.post(
        BACKEND_URL + "/v1/admin/report/addIntelFiles", formData, {
            headers:{
                token:localStorage.getItem("token")
            }
        }
    ).then((res) => {
        this.getuploadedDocs()
    }).catch((err) => {
        console.log(err)
    })

  }


  downloadIntelFile =(Key)=> {
    axios.post(
        BACKEND_URL + "/v1/admin/report/downloadIntelFile", {Key: Key}, {
            headers:{
                token:localStorage.getItem("token")
            }
        }
    ).then((res) => {
        window.open(res.data, '_blank')
    }).catch((err) => {
        alert("Something went wrong!")
    })

  }

  deleteIntelFile =(Key)=> {
    const x = window.confirm(`Delete ${Key} ?`)
    if(x) {
      this.setState({isLoading: true})
      axios.post(
          BACKEND_URL + "/v1/admin/report/deleteIntelFile", {Key: Key}, {
              headers:{
                  token:localStorage.getItem("token")
              }
          }
      ).then((res) => {
          this.getuploadedDocs()
      }).catch((err) => {
          alert("Something went wrong!")
      })
    }

  }


  getuploadedDocs = ()=> {
    axios.get(
        BACKEND_URL + "/v1/admin/report/getIntelFiles", {
            headers:{
                token:localStorage.getItem("token")
            }
        }
    ).then((res) => {
        this.setState({list: res.data, isLoading: false})
    }).catch((err) => {
        this.setState({list: [], isLoading: false})
        console.log(err)
    })
  }


  render() {
    const { list, isLoading } = this.state;

    const re = /(?:\.([^.]+))?$/;
    const listNew = list.map( (el)=> {
      const o = Object.assign({}, el);
      const ext = re.exec(o.Key)[1]; 
      o.type = ext;
      return o;
    })
    const grouped = _.groupBy(listNew, 'type')

    return (
      <div>
        <div className="col-md-12 topbar">
            <Navbar />
        </div>

        <main role="main" className="p-5">
          <div className="my-3 p-3 bg-white rounded shadow-sm">
            <h6 className="border-bottom border-gray pb-2 mb-0">BIS Workspace</h6>
            <div className="card">
              <div className="card-body">
              <h5 className="card-title">Intel Uploaded Documents List</h5>

              <Form onSubmit={this.uploadDocs}>
                <div className="col-md-12">
                    <Form.Group className="float-right">
                        <Form.Control required type="file" onChange={(evt) => {
                            this.setState({file: evt.target.files[0]});
                        }}>
                        </Form.Control>
                        <Button className="mt-2" varient="primary" type="submit">
                            Upload New File
                        </Button>
                    </Form.Group>
                </div>
              </Form>
              
              <Table striped bordered hover size="sm">
                <tbody>
                  { !isLoading && list.length == 0 &&
                    <tr>
                      <td colSpan="3">No Files Available</td>
                    </tr>
                  }
                </tbody>
              </Table>
              {
                  !isLoading && Object.entries(grouped).map(([key, value]) => 
                      <Table striped bordered hover size="sm" key={key} className="mt-3">
                        <thead>
                          <tr>
                            <th>{key.toUpperCase()} Files</th>
                            <th style={{width: 150}}>Last Modified</th>
                            <th style={{width: 350}}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                            { value.length > 0 && value.map((o, index)=>
                                <tr key={index}>
                                  <td>{o.Key}</td>
                                  <td>{Moment(o.LastModified).fromNow()}</td>
                                  <td>
                                    <button className="btn btn-success btn-sm mr-2" onClick={()=> this.downloadIntelFile(o.Key)}>Download</button>
                                    <button className="btn btn-danger btn-sm" onClick={()=> this.deleteIntelFile(o.Key)}>Delete</button>
                                  </td>
                                </tr>
                              )}
                        </tbody>
                      </Table>
                  )

              }
              
              { isLoading &&
                <div style={{textAlign: 'center'}}>
                  <Loader
                   type="Oval"
                   color="#00BFFF"
                   height={100}
                   width={100}
                  /> 
                </div>
              }

              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state){
    return { }
}

export default withRouter(connect(
  mapStateToProps
)(Documents))

